import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Drawer
} from "@material-ui/core";

import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import clsx from "clsx";
import GuestTypeConfigurationForm from "./GuestTypeConfigurationForm";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import Title from "../Title";

/* Services */
import apiClient from "../../auth/apiClient";
import GuestTypeService from "../../services/GuestTypeService";
import RateService from "../../services/RateService";
import { ENTITY_TYPE } from "../../constants";
import { FindEntity } from "../../state/slices/entities";
import { useSelector, shallowEqual } from "react-redux";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectParentEntity} from "../../state/slices/CoreEntity";

const GuestTypesEditor = ({ entityID, onClose }) => {
  const classes = useStyles();
  const [guesttypeDetail, setGuestTypeDetail] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [GuestTypesData, setGuestTypesMappings] = useState([]);
  const { showDialog } = useConfirmationDialog();
  const [systemguesttypes, setSystmeGuestTypes] = useState([]);
  const [rates, setRates] = useState([]);
  const [drawerState, setDrawerState] = useState(false);
  const [isGuestTypeInUse, setIsGuestTypeInUse] = useState(false);
  const useCoreEntitySlice = useCoreEntityContext();

  const parentEntity = useSelector((state) => {
    if(useCoreEntitySlice) return selectParentEntity(entityID);

    const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return FindEntity(state.entities?.EntityList ?? [], entity.parententityid);
  }, shallowEqual);

  const service = new GuestTypeService(apiClient);
  const rateService = new RateService(apiClient);
  
//"isEditable" variable is used to check whether guest types can be editable or not.
  //(Valet Area, Facility Group Under Tenant ->Editable)
  //(Valet Area under Facilty Group -> View Only).
  const isEditable = parentEntity.typeid === ENTITY_TYPE.FacilityGroup ? false : true;

  const defaultGuestTypeDetail = {
    guestTypeID: "",
    entityID: entityID,
    name: "",
    ticketStartRange: "",
    ticketEndRange: "",
    prefix: "",
    systemGuestTypeID: "",
    rateID: "",
  };

  const saveGuestType = async (objToUpdate) => {
    try {
      if (objToUpdate.guestTypeID != '') {
        await service.updateSingleGuestType(objToUpdate);
      } else {
        await service.createGuestType(objToUpdate);
      }
      GetGuestTypeMappingInfo();
      setDrawerState(false);
    } catch (error) {

      if (error.response?.data) {
        enqueueSnackbar(
          `Failed to Save Guest Type: ${error.response.data.message ??
          error.response.data.errors?.TicketStartRange ??
          error.response.data.errors?.TicketEndRange ??
          error.response.data.errors?.Name ??
          error.response.data.errors?.Prefix ??
          error.response.data.errors?.TicketLength}`,
          { variant: "error", tag: "UpdateGuestTypeError" }
        );
      } else {
        enqueueSnackbar(
          `Failed to Save Guest Type`,
          { variant: "error", tag: "UpdateGuestTypeError" }
        );
      }

    }
  };

  const handleSelected = async (guesttype) => {
    setIsGuestTypeInUse(false);
    if (isEditable) {
      const response = await service.isGuestTypeUsed(guesttype.guestTypeID);
      if (response.data) {
        enqueueSnackbar(
          `Guest type in use and cannot be edited`,
          { variant: "error", tag: "EditGuestTypeRestricted" }
        );
        setIsGuestTypeInUse(true);
      }
    }
    const guesttypeDetail = { ...guesttype };
    setGuestTypeDetail(guesttypeDetail);
    setDrawerState(true);
  };

  const onAddClick = () => {
    //Since the usecase here is to add a new guesttype, setting the isGuestTypeInUse state to false.
    setIsGuestTypeInUse(false);
    setDrawerState(true);
    setGuestTypeDetail(defaultGuestTypeDetail);
  };

  const createGuestType = async (objToAdd) => {
    try {
      await service.createGuestType(objToAdd);
      GetGuestTypeMappingInfo();
      setDrawerState(false);
    } catch (error) {

      if (error.response?.data) {
        enqueueSnackbar(
          `Failed to Save Guest Type: ${error.response.data.message ??
          error.response.data.errors?.TicketStartRange ??
          error.response.data.errors?.TicketEndRange ??
          error.response.data.errors?.Name ??
          error.response.data.errors?.Prefix ??
          error.response.data.errors?.TicketLength}`,
          { variant: "error", tag: "createGuestTypeError" }
        );
      } else {
        enqueueSnackbar(
          `Failed to Save Guest Type`,
          { variant: "error", tag: "createGuestTypeError" }
        );
      }

    }
  };

  const GetGuestTypeMappingInfo = async () => {
    try {
      let result = await service.getGuestTypes(entityID);
      setGuestTypesMappings(result.data);
    } catch (error) {
      enqueueSnackbar("Failed to get Guest Type List", {
        variant: "error",
        tag: "getGuestTypes"
      });
      setGuestTypesMappings([]);
    }
  }

  useEffect(() => {
    GetGuestTypeMappingInfo();
  }, [entityID])

  const deleteGuestType = async (guesttypeobj) => {
    //Delete should not be allowed if GuestType is in use.
    const guestTypeUsageResponse = await service.isGuestTypeUsed(guesttypeobj.guestTypeID);
    if (guestTypeUsageResponse.data) {
      enqueueSnackbar(
        `Guest type in use and cannot be deleted`,
        { variant: "error", tag: "DeleteGuestTypeRestricted" }
      );
      return;
    }
    let response = await showDialog({
      title: "Delete the " + guesttypeobj.name.toUpperCase(),
      message:
        "Do you want to delete this Guest Type?",
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" }
      ]
    });

    if (response !== "Yes") return;
    try {
      await service.deleteGuestType({ guestTypeID: guesttypeobj.guestTypeID });
      setDrawerState(false);
      GetGuestTypeMappingInfo();
    } catch (error) {
      enqueueSnackbar("Failed to delete Guest Type", {
        variant: "error",
        tag: "FailedToDeleteGuestType"
      });
    }
  };


  useEffect(() => {
    service.getSystemGuestTypes().then((result) => {
      setSystmeGuestTypes(result.data);
    })
      .catch((err) => {
        enqueueSnackbar("Unable to retrieve system Guest types", {
          variant: "error",
          tag: "FailedToGetSystemGuestTypes",
        });

      });

  }, []);

  useEffect(() => {
    let rate = [];
    let entityIDForRate = isEditable ? entityID : parentEntity.entityid;
    rateService.getAvailableRates(entityIDForRate).then((result) => {
      if (result !== null && result.data != null && result.data.length > 0) {
        for (let i = 0; i < result.data.length; i++) {
          rate[i] = {
            name: result.data[i].name,
            rateID: result.data[i].rateBlobID
          };
        }
        setRates(rate);
      } else {
        enqueueSnackbar("No rates exist for this entity", {
          variant: "error",
          tag: "NoRatesExistForThisEntity",
        });
      }
    })
      .catch((err) => {
        enqueueSnackbar("Unable to retrieve rates for this entity", {
          variant: "error",
          tag: "FailedToFindRatesForEntity",
        });
      });

  }, []);

  return (
    <div className={classes.GuestTypesDrawer}>
      <Box className={classes.guesttypeBox}>
        <Grid container item xs={12} sm={12} className={clsx([classes.headerContainerSpacing])}>
          <Grid item xs={8} sm={10}>
            <Title>Guest Types</Title>
          </Grid>
          <Grid item xs={4} sm={2}>
            {isEditable ? (
              <Button
                className={clsx("btn-add", classes.addButton)}
                data-id="add-btn"
                data-testid="add-btn"
                variant="contained"
                color="primary"
                onClick={onAddClick}
              >
                Add
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Divider className={classes.guesttypeDivider} />
        {(isEditable == false && GuestTypesData.length === 0) ? (
          // Display message when GuestTypesData is empty
          <Typography variant="body1">
            No Guest Type Configured in the Parent Facility Group.
          </Typography>
        ) : (
          // Render Guest Types if data is available
          <>
            {GuestTypesData.map((row) => (
              <Grid container key={row.guestTypeID} className={classes.guesttypeLine}>
                <Grid item xs={8} lg={8} className={clsx(classes.guesttypeLabel)}>
                  {row.name}
                </Grid>
                <Grid item xs={4} lg={4}>
                  {isEditable ? (
                    <>
                      <Button
                        className={clsx("button edit", classes.button)}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: '5px' }}
                        onClick={() => handleSelected(row)}
                      >
                        Edit
                      </Button>
                      <Button
                        className={clsx("button delete", classes.deleteButton)}
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: '10px', width: '20px' }}
                        onClick={() => deleteGuestType(row)}
                      >
                        Delete
                      </Button>
                    </>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        className={clsx("button view", classes.button)}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: '10px', width: '20px' }}
                        onClick={() => handleSelected(row)}
                      >
                        View
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Box>
      <Box className={clsx([classes.drawerDetailConfiguration])}>
        <div>
          <Divider className={classes.guesttypeDividerFixed} />
        </div>
        <Button
          className={clsx("guesttype-cancel", classes.cancelBtn)}
          variant="contained"
          data-id="closeBtn"
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
      <Drawer
        className={clsx("drawer", "guesttype-config")}
        anchor="right"
        open={drawerState}
        classes={{
          paper: classes.drawerDetailConfiguration,
        }}
        onClose={() => setDrawerState(false)}
      >
        <GuestTypeConfigurationForm
          handleSave={createGuestType}
          handleEdit={saveGuestType}
          handleCancel={() => setDrawerState(false)}
          guesttype={guesttypeDetail}
          systemguesttypes={systemguesttypes}
          rates={rates}
          isEditable={isEditable && !isGuestTypeInUse}
        />
      </Drawer>
    </div>
  );
};

GuestTypesEditor.defaultProps = {
  entityID: null,
  onClose: () => { }
};

GuestTypesEditor.propTypes = {
  entityID: PropTypes.string,
  onClose: PropTypes.func
};

export default GuestTypesEditor;
